<template>
  <div class="number-stepper-container">
    <button
      class="btn btn-sm"
      :disabled="isMinusDisabled"
      ignore-disable
      force-disable
      @click.prevent="removeQuantity">
      -
    </button>
    <div class="px-2 flex-1">{{value}}</div>
    <button
      class="btn btn-sm"
      :disabled="isPlusDisabled || disabled"
      ignore-disable
      force-disable
      @click.prevent="addQuantity">
      +
    </button>
  </div>
</template>

<script>
export default {
  name: 'HStepper',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 5,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMinusDisabled() {
      return this.value <= this.min;
    },
    isPlusDisabled() {
      return this.disabled || (this.value >= this.max);
    },
  },
  methods: {
    addQuantity() {
      this.$emit('input', this.value + 1);
    },
    removeQuantity() {
      this.$emit('input', this.value - 1);
    },
  },
};
</script>

<style scoped>
.number-stepper-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  gap: 5px;
}
</style>
