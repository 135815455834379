<template>

  <div class="table-responsive" style="max-height: 25rem; overflow-y: scroll;">
    <ul class="nav nav-tabs mx-0" role="tablist">
      <li
        v-for="category in categories"
        :key="category"
        class="nav-item">
        <a
          tabindex="0"
          class="nav-link"
          :class="{'active': tab === category}"
          role="tab"
          @click="tab = category">{{upperFirst(category)}}</a>
      </li>
    </ul>
    <dimmer :active="loading">
      <table class="table text-nowrap">
        <thead>
          <tr>
            <th>Event</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items[tab]" :key="item.id">
            <td v-if="tab === 'order'">
              {{item.user.firstName}} updated the {{item.activity.property}} of
              <router-link
                :to="`/orders/${item.activity.id}`"
                target="_blank"
              >
                #{{item.activity.id}}
              </router-link>
              to {{item.activity.value}}
            </td>
            <td v-else-if="tab === 'selections'">
              {{item.user.firstName}} updated the {{item.activity.property}} to
              <div v-for="recipeId in [item.activity.value].flat()" :key="recipeId">
                <router-link
                  :to="`/recipes/${typeof (recipeId) === 'object' ? recipeId.recipe_id : recipeId}`"
                  target="_blank"
                >
                  {{typeof (recipeId) === 'object' ? (`#${recipeId.recipe_id} - ${recipeId.name} (${recipeId.quantity})`) : `#${recipeId}`}}
                </router-link>
              </div>
              for the week of {{moment(item.activity.week).format('D MMM YYYY')}}
            </td>
            <td v-else-if="tab === 'skippings'">
              {{item.user.firstName}} {{item.activity.action}} the {{item.activity.property}} for {{item.activity.value}}
            </td>
            <td v-else-if="tab === 'complaints'">
              {{item.reportedBy.email}} reported {{IssueTypes[item.category]}} for order <router-link
                :to="`/orders/${item.order.id}`"
                target="_blank"
              >
                #{{item.order.id}}
              </router-link>
            </td>
            <td v-else>
              {{item.user.firstName}} updated the {{item.activity.property}} to {{item.activity.value}}
            </td>
            <td>
              {{moment.utc(item.createdAt).local().format('LTS, D MMM YYYY')}}
            </td>
          </tr>

          <tr v-if="items[tab].length === 0">
            <td colspan="2">The {{tab}} does not have any activity.</td>
          </tr>
        </tbody>
      </table>
    </dimmer>
  </div>

</template>

<script>

import IssueTypes from '@hellochef/shared-js/enums/IssueTypes';
import {subscriptions} from '@/services';

export default {
  props: [
    'value',
    'orders',
  ],
  data() {
    return {
      IssueTypes,
      items: {
        /* eslint-disable sort-keys */
        order: [],
        preferences: [],
        product: [],
        selections: [],
        skippings: [],
        complaints: [],
      },
      tab: 'order',
      loading: false,
    };
  },
  computed: {
    categories() {
      return Object.keys(this.items);
    },
  },
  watch: {
    'value.id'() {
      this.fetchData();
    },
    orders(value) {
      if (value.length) {
        this.items.complaints = this.orders.flatMap(item => item.complaints) || [];
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;

      subscriptions.getActivityById(this.value.id).then(result => {
        Object.assign(this.items, result.activities);
        this.loading = false;
      });
    },
  },
};

</script>
