<template>
  <div :key="selected" class="selector-wrapper d-flex items-center">
    <div class="form-group mb-0 w-100">
      <label for="num_people" class="form-label">People:</label>
      <select
        :value="selectedProduct.number_of_people"
        class="form-control custom-select"
        required
        data-test="input-people-count"
        id="num_people"
        @input="peopleChanged">
        <option
          :value="null"
          disabled
          selected>
          no. of people
        </option>
        <option
          v-for="people in availableNumberOfPeople"
          :key="people"
          :value="people">
          {{people}}
        </option>
      </select>
    </div>

    <div class="form-group mb-0 w-100">
      <label for="num_recipes" class="form-label">Recipes:</label>
      <select
        :value="selectedProduct.number_of_recipes"
        class="form-control custom-select"
        required
        data-test="input-recipe-count"
        id="num_recipes"
        @input="recipesChanged">
        <option
          :value="null"
          disabled
          selected>
          no. of recipes
        </option>
        <option
          v-for="recipe in availableNumberOfRecipes"
          :key="recipe"
          :disabled="!selectedProduct.number_of_people"
          :value="recipe">
          {{recipe}}
        </option>
      </select>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductSelector',
  props: {
    selected: {
      type: Number,
      default: null,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedProduct: {
        number_of_recipes: null,
        number_of_people: null,
        product_id: null,
      },
    };
  },
  computed: {
    groupedProducts() {
      const groupedNumberOfPeople = this.products.reduce((acc, data) => {
        if (!acc[data.number_of_people]) {
          acc[data.number_of_people] = [];
        }

        if (!acc[data.number_of_people].includes(data.number_of_recipes)) {
          acc[data.number_of_people].push(data.number_of_recipes);
        }

        return acc;
      }, {});

      return groupedNumberOfPeople;
    },
    availableNumberOfRecipes() {
      return this.groupedProducts[this.selectedProduct.number_of_people] || [];
    },
    availableNumberOfPeople() {
      return Object.keys(this.groupedProducts).map(people => parseInt(people));
    },
  },
  watch: {
    selected(value) {
      if (value) {
        this.getSelectedProduct(value);
      }
    },
  },
  created() {
    if (this.selected) {
      this.getSelectedProduct(this.selected);
    }
  },
  methods: {
    peopleChanged(e) {
      const value = parseInt(e.target.value);

      Object.assign(this.selectedProduct, {
        number_of_people: value,
        number_of_recipes: null,
        product_id: null,
      });
    },
    recipesChanged(e) {
      const value = parseInt(e.target.value);

      const product = this.products.find(product => product.number_of_people === this.selectedProduct.number_of_people && product.number_of_recipes === value);

      Object.assign(this.selectedProduct, {
        number_of_people: product.number_of_people,
        number_of_recipes: product.number_of_recipes,
        product_id: product.id,
      });

      this.$emit('change', product);
    },
    getSelectedProduct(id) {
      const product = this.products.find(product => product.id === id);

      Object.assign(this.selectedProduct, {
        product_id: product.id,
        number_of_people: product.number_of_people,
        number_of_recipes: product.number_of_recipes,
      });
    },
  },
};
</script>

<style scoped>
.selector-wrapper {
  gap: 5px;
}
</style>
