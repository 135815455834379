<template>
  <div data-test="card-subscription-summary" class="card">
    <div class="card-header" data-test="label-card-header">
      <div v-if="travelMode" class="rounded-full pulse mr-2">
        <img
          v-svg-inline
          data-test="icon-travel-mode"
          class="travel-icon"
          src="@/assets/svg/travel-mode.svg"
          alt="Travel mode"/>
      </div>
      <h3 v-if="!dirty" class="card-title">Subscription summary #{{item.id}}</h3>
      <h3 v-else class="card-title">
        Subscription summary #{{item.id}} (please save your changes to update this)
      </h3>
    </div>
    <div class="card-body">
      <ul>
        <li
          v-if="travelMode"
          data-test="label-travel-mode"
          class="text-danger font-weight-bold">
          Travel mode is active from {{formatDate(travelMode.start_date, 'D MMM YYYY')}} to
          {{formatDate(travelMode.end_date, 'D MMM YYYY')}}
          <i v-if="travelMode.is_customised" class="fe fe-settings"></i>
        </li>
        <li v-if="item.cancellationDate" data-test="label-cancellation-date">
          Cancellation date: {{formatDate(item.cancellationDate)}}
        </li>
        <li v-if="item.resubscriptionDate" data-test="label-resubscription-date">
          Resubscription date: {{formatDate(item.resubscriptionDate)}}
        </li>
        <li data-test="label-next-payment-date">
          Next payment: {{formatDate(item.nextPaymentDate)}}
        </li>
        <li data-test="label-next-delivery-date">
          Next delivery: {{formatDate(item.nextDeliveryDate)}}
        </li>
        <li data-test="label-last-delivery-date">
          Last delivery: {{formatDate(item.lastDeliveryDate)}}
        </li>
        <li data-test="label-first-delivery-date">
          First delivery: {{formatDate(item.preferences.firstDeliveryDate)}}
        </li>
        <li data-test="label-total-orders">
          Total processing/completed orders: {{item.orders}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'SubscriptionSummary',
  props: {
    item: {
      type: Object,
      required: true,
    },
    dirty: {
      type: Boolean,
      default: false,
    },
    travelModes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    travelMode() {
      return this.travelModes?.length ? this.travelModes[0] : null;
    },
  },
  methods: {
    formatDate(date, format = 'dddd, D MMM YYYY') {
      return moment(date).format(format);
    },
  },
};
</script>

<style scoped>
.travel-icon {
  width: 24px;
  height: 24px;
}
</style>
