<template>

  <table class="table text-nowrap table-vcenter">
    <thead>
      <tr>
        <th>Box</th>
        <th class="text-center">Box price</th>
        <th v-if="value.gourmetSurcharge !== undefined" class="text-center">Recipe surcharge</th>
        <th v-if="value.marketSurcharge !== undefined" class="text-center">Market Surcharge</th>
        <th class="text-center">Delivery fee</th>
        <th v-if="value.discount !== undefined" class="text-center">Discount</th>
        <th class="text-center">Subtotal</th>
        <th class="text-center">VAT</th>
        <th class="text-center">Grand total</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td v-if="typeof product === 'object'">
          {{`${product.menu.name} box with ${product.number_of_recipes} recipes for ${product.number_of_people} persons`}}
        </td>
        <td v-else>{{product}}</td>
        <td class="text-center">AED {{numeral(value.boxPrice).format('0,0[.]00')}}</td>
        <td v-if="value.gourmetSurcharge !== undefined" class="text-center">AED {{numeral(value.gourmetSurcharge).format('0,0[.]00')}}</td>
        <td v-if="value.marketSurcharge !== undefined" class="text-center">AED {{numeral(value.marketSurcharge).format('0,0[.]00')}}</td>
        <td class="text-center">AED {{numeral(value.deliveryFee).format('0,0[.]00')}}</td>
        <td v-if="value.discount !== undefined" class="text-center">AED {{numeral(value.discount).format('0,0[.]00')}}</td>
        <td class="text-center">AED {{numeral(value.subtotal).format('0,0[.]00')}}</td>
        <td class="text-center">AED {{numeral(value.vat).format('0,0[.]00')}}</td>
        <td class="text-center">AED {{numeral(value.grandTotal).format('0,0[.]00')}}</td>
      </tr>
      <slot></slot>
    </tbody>
  </table>

</template>

<script>

export default {

  props: [

    'product',
    'value',
  ],
};

</script>
