<template>

  <div data-test="card-user" class="card">
    <div class="card-header">
      <h3 class="card-title">
        {{displayName(value.user)}}
      </h3>
      <router-link
        :to="`/users/${value.id}`"
        target="_blank"
        rel="noopener noreferrer"
        class="ml-auto">
        <i class="fe fe-external-link"></i>
      </router-link>
    </div>
    <div class="card-body">
      <div class="form-row">

        <div class="form-group col-sm-6">
          <label class="form-label">Email</label>
          <a
            :href="`mailto:${value.user.email}`"
            target="_blank"
            rel="noopener noreferrer">{{value.user.email}}</a>
        </div>
        <div class="form-group col-sm-6">
          <label class="form-label">Phone</label>
          <a
            :href="`tel:${value.user.phone}`"
            target="_blank"
            rel="noopener noreferrer">{{value.user.phone}}</a>
        </div>

      </div>
      <div class="form-row">

        <div class="form-group col-sm-6">
          <label class="form-label">Delivery address</label>
          <span>{{displayAddress(value.deliveryAddress)}}</span>
        </div>
        <div class="form-group col-sm-6">
          <label class="form-label">Billing address</label>
          <span v-if="value.billingAddressEqualsDelivery">Same as delivery</span>
          <span v-else>{{displayAddress(value.billingAddress)}}</span>
        </div>

      </div>
      <div class="form-row">

        <div class="form-group col-sm-6">
          <label class="form-label">Store credit</label>
          <span>
            AED {{numeral(value.storeCredit).format('0,0[.]00')}} <a :href="`/store-credit?query=${value.user.email}`">available</a> (AED {{numeral(value.storeCreditUsed).format('0,0[.]00')}} used)
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label class="form-label">Referral credit</label>
          <span>
            AED {{numeral(value.referralCredit).format('0,0[.]00')}} <a :href="`/referrals?query=${value.user.email}`">available</a> (AED {{numeral(value.referralCreditUsed).format('0,0[.]00')}} used)
          </span>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

export default {

  props: [

    'value',
  ],
};

</script>
