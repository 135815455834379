<template>
  <dimmer :active="loading">
    <div class="container">
      <edit-header
        entity="subscription"
        :error.sync="error"
        :errorMessage.sync="errorMessage"
        :invalid.sync="invalid"
        :submitted.sync="submitted"
        :isNew="isNew">
      </edit-header>

      <div v-if="!!repeatOffenderMessage" class="alert alert-icon alert-danger alert-dismissible pulse pulse-limit">
        <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
        {{repeatOffenderMessage}}
      </div>

      <form
        v-disable-all="!can(uiPermissions.SUBSCRIPTIONS_UPDATE)"
        ref="form"
        class="validation"
        novalidate
        @submit.prevent="saveSubscription">
        <!-- Subscription summary & User card  -->
        <template v-if="!isNew && item.customer">
          <div class="row row-cards row-deck">
            <div class="col-lg-6">
              <subscription-summary
                :item="item"
                :travel-modes="travelModes"
                :dirty="dirty"/>
            </div>
            <div class="col-lg-6">
              <user-card :value="item.customer"></user-card>
            </div>
          </div>
        </template>

        <!-- Subscription details -->
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-header justify-content-between">
                <h3 class="card-title">Subscription details</h3>
              </div>
              <div class="card-body">
                <div class="row row-cards row-deck">
                  <div :class="isNew ? 'col-lg-5' : 'col-lg-4'">
                    <div data-test="card-subscription" class="card">
                      <div class="card-header">
                        <h3 class="card-title">Subscription</h3>
                      </div>
                      <div class="card-body reduced-card-space">
                        <div v-if="isNew" class="form-group">
                          <label class="form-label">User</label>
                          <user-selector v-model="item.customer" :required="true"></user-selector>
                        </div>
                        <div v-if="products.length" class="form-group">
                          <product-selector
                            :selected="product ? product.id : null"
                            :products="filterProducts"
                            @change="changeSubscriptionProduct"/>
                        </div>
                        <div class="form-group">
                          <label class="form-label">Status</label>
                          <select
                            v-model="item.status"
                            data-test="input-subscription-status"
                            class="form-control custom-select"
                            required
                            @change="subscriptionDirty = true">
                            <option value="" disabled>
                              Select status
                            </option>
                            <option
                              v-for="(value, key) in SubscriptionStatuses"
                              :key="value"
                              :value="value">
                              {{key}}
                            </option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label class="form-label">Meal preference</label>
                          <select
                            v-model="mealPreference"
                            class="form-control custom-select"
                            @change="subscriptionDirty = true">
                            <option :value="null">No preference</option>
                            <option
                              v-for="value in mealPreferences"
                              :key="value.id"
                              :value="value">
                              {{value.name}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- #region Delivery preference -->
                  <form
                    ref="deliveryForm"
                    :class="isNew ? 'col-lg-5' : 'col-lg-4'"
                    @submit.prevent="handleSaveDelivery">
                    <div data-test="card-delivery" class="card">
                      <div class="card-header">
                        <h3 class="card-title">Delivery</h3>
                      </div>
                      <div
                        v-for="(deliveryPreference, index) in deliveryPreferences"
                        :key="deliveryPreference.id"
                        class="card-body reduced-card-space delivery-row"
                        :data-test="`deliveryPreferenceRow${index}`">
                        <div class="form-group delivery-input">
                          <label class="form-label">Day {{index + 1}}</label>
                          <select
                            v-model="deliveryPreference.day"
                            data-test="input-delivery-day"
                            class="form-control custom-select"
                            required
                            @change="handleSubscriptionDeliveryDayChange(index)">
                            <option :value="null" disabled>Select day</option>
                            <option
                              v-for="value in deliveryDays"
                              :key="value.id"
                              :value="value">
                              {{value.name}}
                            </option>
                          </select>
                        </div>
                        <div class="form-group delivery-input">
                          <label class="form-label">Time {{index + 1}}</label>
                          <select
                            v-model="deliveryPreference.time"
                            data-test="input-delivery-time"
                            class="form-control custom-select"
                            required
                            @change="deliveryDirty = true">
                            <option :value="null" disabled>Select time</option>
                            <option
                              v-for="value in deliveryPreference.timesForDay"
                              :key="value.id"
                              :value="value">
                              {{value.displayName}}
                            </option>
                          </select>
                        </div>
                        <i
                          v-if="index !== 0"
                          v-b-tooltip.hover="'Click save after deleting to apply changes'"
                          :data-test="`delete-delivery-btn${index}`"
                          class="fe fe-trash delete-icon text-danger"
                          @click="handleRemoveDelivery(index)"></i>
                        <div v-if="isNew" class="form-group">
                          <label class="form-label">First delivery date</label>
                          <select
                            v-model="firstDeliveryDate"
                            class="form-control custom-select"
                            required
                            @change="subscriptionDirty = true">
                            <option :value="null" disabled>Select date</option>
                            <option
                              v-for="(value, index) in firstDeliveryDates"
                              :key="index"
                              :value="value.format('YYYY-MM-DD')">
                              {{value.format('D MMM YYYY')}}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div v-if="!isNew && deliveryPreferences.length" class="d-flex justify-content-end mb-2 mr-5">
                        <button
                          data-test="add-delivery-btn"
                          class="btn btn-outline-info w-9"
                          :disabled="submitting"
                          type="button"
                          @click="handleAddDelivery">
                          Add split
                        </button>
                      </div>

                      <div v-if="deliveryDirty" class="card-footer mt-auto">
                        <button
                          v-b-tooltip.hover="'Please save delivery separate from subscription save'"
                          data-test="save-delivery-btn"
                          class="btn btn-primary float-right"
                          :disabled="submitting"
                          type="submit">
                          Save delivery
                        </button>
                      </div>
                    </div>
                  </form>
                  <!-- #endregion -->
                  <div v-if="!isNew" class="col-lg-4">
                    <div data-test="card-payment" class="card">
                      <div class="card-header">
                        <h3 class="card-title">Payment</h3>
                        <a
                          v-if="item.customer && item.customer.user.checkoutLink"
                          :href="item.customer.user.checkoutLink"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="ml-auto">
                          <i class="fe fe-external-link"></i>
                        </a>
                      </div>
                      <div class="card-body reduced-card-space">
                        <div class="form-group">
                          <label class="form-label">Credit card
                            <span v-if="item.creditCard" class="text-capitalize">({{item.creditCard.psp}})</span>
                          </label>
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              :value="item.creditCardNumber"
                              readonly
                              @change="subscriptionDirty = true"/>
                            <div v-if="item.creditCard" class="input-group-append">
                              <span class="input-group-text">{{item.creditCard.status}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Cancelled -->
                <div v-if="item.status === SubscriptionStatuses.Canceled" class="row">
                  <div class="col">
                    <div data-test="card-cancellation-reasons" class="card">
                      <div class="card-header">
                        <h3 class="card-title">Cancellation reasons</h3>
                      </div>
                      <div class="card-body row">
                        <div class="btn-list">
                          <button
                            v-for="(item, index) in cancellationReasons"
                            :key="index"
                            type="button"
                            class="btn btn-pill"
                            :class="item.selected ? 'btn-primary' : 'btn-outline-primary'"
                            @click="item.selected = !item.selected">
                            {{item.text}}
                          </button>
                        </div>
                        <textarea
                          v-model="cancellationOther"
                          class="form-control mt-6"
                          placeholder="Other reason"></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="subscriptionDirty" class="row">
                  <div class="col d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center gap-4 text-muted">
                      <i class="fe fe-info"></i>
                      Please save updated subscription details before making any selections or customizing the weeks.
                    </div>
                    <button
                      class="btn btn-primary float-right"
                      :disabled="submitting"
                      type="submit">
                      Save subscription
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Skipping and recipe selection -->
        <div v-if="!isNew" class="row">
          <div class="col">
            <div data-test="card-recipe-selection" class="card">
              <div class="card-header">
                <h3 class="card-title">Skipping and recipe selection</h3>
              </div>
              <div class="table-responsive">
                <table class="table card-table text-nowrap">
                  <thead>
                    <tr>
                      <th class="w-1"></th>
                      <th>Week</th>
                      <th>Renewal</th>
                      <th>Delivery</th>
                      <th>Delivery Day</th>
                      <th>Skip</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(week, index) in item.schedule"
                    :key="index">
                    <tr
                      :class="[{ 'table-secondary': week.is_skipped_by_frequency }, {'highlighted-row': expandedRow === index}]"
                      class="h-0 align-middle cursor-pointer header-row"
                      @click="expandRow(week, index)">
                      <td class="text-center align-middle">{{index + 1}}</td>
                      <td class="align-middle">{{weekName(week.startDate, { weekFrom: false })}}</td>
                      <td class="align-middle">{{week.paymentDate.format('dddd, D MMM YYYY')}}</td>
                      <td class="align-middle">{{week.deliveryDate.format('dddd, D MMM YYYY')}}</td>
                      <td class="align-middle">
                        <div class="mb-0">
                          <select
                            v-model="week.deliveryDay"
                            class="form-control form-control-sm"
                            force-disable
                            :disabled="week.skipped"
                            required
                            @change="handleDeliveryDayChange(week)">
                            <option :value="null" disabled>Select day</option>
                            <option
                              v-for="value in deliveryDays"
                              :key="`${week.startDate}-${value.id}`"
                              :value="value.name">
                              {{value.name}}
                            </option>
                          </select>
                        </div>
                      </td>
                      <td class="align-middle">
                        <div class="center">
                          <label
                            v-b-tooltip.hover="!week.hasTravelMode && ((week.skipped ? 'Unskip' : 'Skip') + ' will be applied automatically on toggle')"
                            class="custom-switch position-relative">
                            <input
                              v-if="week.is_skipped_by_frequency"
                              v-model="week.is_skipped_by_frequency"
                              disabled
                              type="checkbox"
                              class="custom-switch-input">
                            <input
                              v-else
                              v-model="week.skipped"
                              type="checkbox"
                              :disabled="week.hasTravelMode || !can(uiPermissions.SUBSCRIPTIONS_UPDATE)"
                              force-disable
                              ignore-disable
                              class="custom-switch-input"
                              @change="skipUnskipWeek(week)">
                            <span class="custom-switch-indicator"></span>
                            <img
                              v-if="week.hasTravelMode"
                              v-svg-inline
                              data-test="icon-travel"
                              class="travel-icon ml-2"
                              src="@/assets/svg/travel.svg"
                              alt="Travel"/>
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr
                      v-if="expandedRow === index"
                      class="customize-row"
                      :class="{ 'd-none': week.skipped }">
                      <td colspan="100%" class="p-0">
                        <form @submit.prevent="submitCustomWeek(week, index)">
                          <div class="border-bottom">
                            <template v-if="week.errors.length">
                              <div
                                v-for="(weekError, index) in week.errors"
                                :key="index"
                                class="alert alert-icon alert-danger alert-dismissible mb-0">
                                <button
                                  type="button"
                                  class="close"
                                  @click="week.errors = []"></button>
                                <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
                                <div class="whitespace-break-spaces">
                                  {{weekError.join(',') || 'Something went wrong; couldn\'t save your changes.'}}
                                </div>
                              </div>
                            </template>
                            <div v-if="week.success" class="alert alert-icon alert-success alert-dismissible mb-0">
                              <button
                                type="button"
                                class="close"
                                @click="week.success = false"></button>
                              <i class="fe fe-alert-check mr-2" aria-hidden="true"></i>
                              Your changes have been saved successfully.
                            </div>
                            <table
                              class="table w-100 mb-0"
                              :class="[week.is_week_delivery_customized || week.is_week_product_customized ? 'bg-customized' : 'bg-white']">
                              <thead v-if="week.is_week_delivery_customized || week.is_week_product_customized">
                                <tr>
                                  <th colspan="2">
                                    <div class="d-flex align-items-center gap-4">
                                      <i class="fe fe-settings"></i>
                                      Customized
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <td v-if="products.length">
                                  <product-selector
                                    :selected="week.product.id"
                                    :products="filterProducts"
                                    @change="product => changedProductWeek(product, week)"/>
                                </td>
                                <td>
                                  <div class="form-group mb-0">
                                    <label for="time" class="form-label">Delivery Time:</label>
                                    <select
                                      v-model="week.deliveryTime"
                                      class="form-control custom-select"
                                      required
                                      id="time"
                                      @change="week.preferenceDirty = true">
                                      <option :value="null" disabled>Select time</option>
                                      <option
                                        v-for="value in weekDeliveryTimes(week)"
                                        :key="`${week.startDate}-${value.id}`"
                                        :value="value.name">
                                        {{value.name}}
                                      </option>
                                    </select>
                                  </div>
                                </td>

                                <td class="save-btn-container">
                                  <div
                                    v-if="week.dirtySelections || week.dirtyMarketSelections || week.productDirty || week.preferenceDirty"
                                    class="pt-3 text-right">
                                    <button
                                      class="btn btn-primary"
                                      force-disable
                                      :disabled="submitting"
                                      type="submit">
                                      Save
                                    </button>
                                  </div>
                                </td>
                              </tbody>
                            </table>
                          </div>
                          <div class="recipe-category-container">
                            <div class="dinner-category-container">
                              <h5 class="bg-light">Dinner recipes</h5>

                              <div v-if="recipeSelectionBroken" class="text-center">Save your changes first.</div>
                              <div v-else-if="!Object.keys(week.recipes).length" class="text-center">N/A</div>
                              <div v-else class="recipe-columns">
                                <template v-for="value in week.recipes">
                                  <div
                                    :key="value.id"
                                    class="row-default"
                                    :class="{ 'row-selected': getSelection(week.selection, value.id) }">
                                    <recipe-selector
                                      :number="value.id"
                                      :quantity="getSelection(week.selection, value.id)"
                                      :max="week.product.number_of_recipes"
                                      :disabled="checkRecipeQuantity(week) || isSwapSelected(week, value)"
                                      :name="value.name"
                                      :has-swaps="!!(value.swaps && value.swaps.length)"
                                      :price="getGourmetPrice(value, week.product)"
                                      is-gourmet
                                      @change="quantity => setRecipeQuantity(week, { recipe_id: value.id, quantity })"/>
                                  </div>
                                  <div
                                    v-for="swap in value.swaps"
                                    :key="`swap-${swap.id}`"
                                    class="row-default"
                                    :class="{ 'row-selected': getSelection(week.selection, swap.id) }">
                                    <recipe-selector
                                      class="swap-recipe"
                                      has-swaps
                                      highlight-swap
                                      :number="swap.id"
                                      :quantity="getSelection(week.selection, swap.id)"
                                      :max="week.product.number_of_recipes"
                                      :disabled="checkRecipeQuantity(week) || isSwapSelected(week, swap)"
                                      :name="swap.name"
                                      @change="quantity => setRecipeQuantity(week, { recipe_id: swap.id, quantity })"/>
                                  </div>
                                </template>
                              </div>
                            </div>

                            <div class="category-separator"/>

                            <div class="market-category-container">
                              <h5 class="bg-light">Market recipes</h5>

                              <div v-if="recipeSelectionBroken" class="text-center">Save your changes first.</div>
                              <div v-else-if="!Object.keys(week.marketRecipes).length" class="text-center">N/A</div>
                              <div v-else class="recipe-columns">
                                <template v-for="(recipes, key, index) in getTransformedMarketRecipes(week.marketRecipes)">
                                  <h6 :key="`${key}-${index}-header`">{{key}}</h6>
                                  <div :key="`${key}-${index}-recipes`" class="market-type-section">
                                    <template v-for="value in recipes">
                                      <div
                                        :key="value.id"
                                        class="row-default"
                                        :class="{ 'row-selected': getSelection(week.marketSelection, value.id) }">
                                        <recipe-selector
                                          :number="value.id"
                                          :quantity="getSelection(week.marketSelection, value.id)"
                                          :max="MAX_MARKET_ITEMS_SELECTABLE"
                                          :disabled="checkMarketRecipeQuantity(week)"
                                          :name="value.name"
                                          :price="value.price"
                                          @change="quantity => setMarketRecipeQuantity(week, { recipe_id: value.id, quantity })"/>
                                      </div>
                                    </template>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </form>

                        <hr/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Pricing -->
        <div v-if="product" class="row">
          <div class="col">
            <div data-test="card-pricing" class="card">
              <div class="card-header">
                <h3 class="card-title">Pricing</h3>
              </div>
              <div class="table-responsive">
                <receipt-table
                  :value="receipt"
                  :product="item.product"
                  class="card-table">
                </receipt-table>
              </div>
            </div>
          </div>
        </div>

        <!-- Latest orders -->
        <div v-if="!isNew && item.id" class="row">
          <div class="col">
            <div data-test="card-latest-orders" class="card">
              <div class="card-header">
                <h3 class="card-title">Latest orders</h3>
              </div>
              <div class="table-responsive">
                <subscription-orders-table
                  :value="item"
                  class="card-table"
                  @orders="orders => this.orders = orders"/>
              </div>
            </div>
          </div>
        </div>

        <!-- Latest activity -->
        <div v-if="!isNew && item.id" class="row">
          <div class="col">
            <div data-test="card-latest-activity" class="card">
              <div class="card-header">
                <h3 class="card-title">Latest activity</h3>
              </div>
              <subscription-activity-table :orders="orders" :value="item"></subscription-activity-table>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Selection removal confirmation modal -->
    <recipes-changed-modal ref="recipesChangedModal"></recipes-changed-modal>
  </dimmer>
</template>

<script>
import EditHeader from '@/components/EditHeader';
import ProductSelector from '@/components/ProductSelector.vue';
import ReceiptTable from '@/components/ReceiptTable';
import SubscriptionActivityTable from '@/components/SubscriptionActivityTable';
import SubscriptionOrdersTable from '@/components/SubscriptionOrdersTable';
import UserCard from '@/components/UserCard';
import UserSelector from '@/components/UserSelector';
import RecipeSelector from '@/components/shared/recipe-selector/index.vue';
import edit from '@/mixins/edit';
import {deliveryCities, mealPreferences, products, subscriptions, weeklyMenusBasic, travelMode, repeatOffenders, market} from '@/services';
import {pastCutOffTime} from '@/utils';
import SubscriptionStatuses from '@hellochef/shared-js/enums/SubscriptionStatuses';
import {dayInThursdayBasedWeek} from '@hellochef/shared-js/helpers';
import Recipes from '@hellochef/shared-js/services/Recipes';
import Subscriptions from '@hellochef/shared-js/services/Subscriptions';
import moment from 'moment-timezone';
import {mapActions} from 'vuex';
import RecipesChangedModal from './components/RecipesChangedModal.vue';
import SubscriptionSummary from './components/SubscriptionSummary.vue';
import {MAX_MARKET_ITEMS_SELECTABLE} from '@/constants/max-market-items-selectable';
import {captureException} from '@sentry/vue';

const EVERY_ONE_WEEK = 1;
const EVERY_TWO_WEEKS = 2;
const EVERY_FOUR_WEEKS = 4;
const DELIVERY_FREQUENCY = [EVERY_ONE_WEEK, EVERY_TWO_WEEKS, EVERY_FOUR_WEEKS];
const MAX_DELIVERIES = 2;

export default {
  components: {
    EditHeader,
    ReceiptTable,
    SubscriptionActivityTable,
    SubscriptionOrdersTable,
    UserCard,
    UserSelector,
    ProductSelector,
    RecipeSelector,
    RecipesChangedModal,
    SubscriptionSummary,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      DELIVERY_FREQUENCY,
      MAX_MARKET_ITEMS_SELECTABLE,
      SubscriptionStatuses,
      cancellationOther: '',
      cancellationReasons: [
        {selected: false, text: 'The quality of the ingredients is not to my standards'},
        {selected: false, text: 'Items were missing in the box'},
        {selected: false, text: 'I\'m travelling temporarily'},
        {selected: false, text: 'I\'m leaving the UAE permanently'},
        {selected: false, text: 'Serving size is too much'},
        {selected: false, text: 'It\'s too expensive'},
        {selected: false, text: 'The food does not suit my diet or taste'},
        {selected: false, text: 'I don\'t have time to cook'},
        {selected: false, text: 'There\'s too much packaging'},
        {selected: false, text: 'Dissatisfied with delivery'},
      ],
      changes: {},
      deliveryCities: [],
      item: {
        creditCardNumber: null,
        customer: null,
        preferences: {
          deliveryDay: null,
          deliveryTime: null,
          firstDeliveryDate: null,
          mealPreference: null,
        },
        product: null,
        schedule: [],
        status: SubscriptionStatuses.Pending,
        delivery_frequency: 1,
      },
      mealPreferences: [],
      orders: [],
      products: [],
      weeklyMenus: [],
      travelModes: [],
      subscriptionDirty: false,
      deliveryDirty: false,
      repeatOffender: null,
      expandedRow: -1,
      deliveryPreferences: [{
        id: null,
        day: null,
        time: null,
        timesForDay: [],
        splitNumber: 1,
      }],
    };
  },
  computed: {
    isSwapSelected() {
      return (week, recipe) => {
        const allRecipes = [...week.recipes, ...week.recipes.flatMap(recipe => recipe.swaps || [])];
        const populateSelections = week.selection.filter(selection => selection.quantity).map(selection => allRecipes.find(recipe => recipe.id === selection.recipe_id));

        return !!populateSelections.find(selection => selection.number === recipe.number && selection.id !== recipe.id);
      };
    },
    checkRecipeQuantity() {
      return week => {
        const totalQuantity = week.selection.reduce((acc, data) => {
          acc = acc + data.quantity;
          return acc;
        }, 0);
        return totalQuantity >= week?.product.number_of_recipes;
      };
    },
    checkMarketRecipeQuantity() {
      return week => {
        const totalQuantity = week.marketSelection.reduce((acc, data) => {
          acc = acc + data.quantity;
          return acc;
        }, 0);
        return totalQuantity >= MAX_MARKET_ITEMS_SELECTABLE;
      };
    },
    getSelection() {
      return (selections, recipeId) => selections?.find(selected => selected.recipe_id === recipeId)?.quantity || 0;
    },
    deliveryCity() {
      return this.item.customer ? this.deliveryCities.find(x => x.name === this.item.customer.deliveryAddress.city) : null;
    },
    travelModeMessage() {
      return this.travelModes.length ? `Customer is traveling ${this.travelModes[0].start_date} to ${this.travelModes[0].end_date}.` : '';
    },
    deliveryDays() {
      return this.deliveryCity ? this.deliveryCity.days : [];
    },
    weekDeliveryTimes() {
      return week => this.item.preferences.deliveryDay && this.deliveryDays.length ? this.deliveryDays.find(x => x.name === week.deliveryDay)?.times : [];
    },
    firstDeliveryDate: {

      get() { return this.item.preferences.firstDeliveryDate ? this.item.preferences.firstDeliveryDate.format('YYYY-MM-DD') : null; },
      set(newValue) { this.item.preferences.firstDeliveryDate = moment(newValue); },
    },
    firstDeliveryDates() {
      const dates = [];

      if (this.item.preferences.deliveryDay) {
        // Do not impose any cut-off limits on admin panel users.

        [...this.weeklyMenus].sort((a, b) => a.startDate - b.startDate).forEach(week => {
          const date = dayInThursdayBasedWeek(week.startDate, this.item.preferences.deliveryDay.name);

          if (date.isSameOrAfter(moment(), 'day')) { dates.push(date); }
        });
      }

      return dates;
    },
    mainCancellationReasons() {
      return this.cancellationReasons.filter(reason => reason.selected).map(reason => reason.text);
    },
    mealPreference: {

      get() { return this.item.preferences.mealPreference ? this.mealPreferences.find(x => x.id === this.item.preferences.mealPreference.id) : null; },
      set(newValue) { this.item.preferences.mealPreference = newValue; },
    },
    menuType() {
      return this.product?.menu?.name || '';
    },
    product: {
      get() { return this.item.product ? this.products.find(x => x.id === this.item.product.id) : null; },
      set(newValue) {
        this.item.product = newValue;
        this.item.schedule.forEach(week => {
          week.dirtySelections = false;
          week.dirtyMarketSelections = false;
        });
      },
    },
    receipt() {
      return Subscriptions.receipt(this.item, this.customer);
    },
    recipeSelectionBroken() {
      return this.old && this.item.product.menu.id !== this.old.product.menu.id;
    },
    route() {
      return `/subscriptions/${this.item.id}`;
    },
    filterProducts() {
      return this.products;
    },
    repeatOffenderMessage() {
      const noOfCancellation = this.repeatOffender?.no_of_cancellations;

      if (!noOfCancellation || noOfCancellation < 3) return '';

      // for 3 cancellations in 3 months
      if (noOfCancellation === 3) {
        return 'This customer has cancelled 3 times in the past 3 months. Do not issue a refund on their next cancellation.';
      }

      // for more than 3 cancellations in 3 months
      return `This customer has cancelled ${noOfCancellation} times in the past 3 months. Do not issue a refund.`;
    },
  },
  watch: {
    mealPreference: {
      handler(val, oldVal) {
        if (!oldVal) {
          return;
        }

        this.changes.mealPreference = val.name;
      },
    },
    product: {
      handler(val, oldVal) {
        if (!oldVal) {
          return;
        }

        this.changes.product = val.name;
      },
    },
    'item.status': {
      handler(val, oldVal) {
        // By default the value is 'pending'
        if (oldVal === 'pending') {
          return;
        }

        this.changes.itemStatus = val;
      },
    },
    'item.schedule': {
      handler(val, oldVal) {
        if (oldVal.length === 0) {
          return;
        }

        // The week was skipped / unskipped
        val.filter(week => week.dirty).forEach(week => {
          if (!this.changes.skipped) {
            this.changes.skipped = {};
          }

          this.changes.skipped[week.startDate.format('YYYY-MM-DD')] = week.skipped;
        });

        // The week's recipe selections were changed
        val.filter(week => week.dirtySelections).forEach(week => {
          if (!this.changes.selections) {
            this.changes.selections = {};
          }

          this.changes.selections[week.startDate.format('YYYY-MM-DD')] = week.selection;
        });
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('events', ['track']),
    changeSubscriptionProduct(selectedProduct) {
      this.product = selectedProduct;
      this.subscriptionDirty = true;
    },
    changedProductWeek(product, week) {
      if (product.number_of_recipes !== week.product.number_of_recipes) {
        week.selection = [];
      }
      week.product = product;
      week.productDirty = true;
    },
    async fetchAll(id) {
      const response = await Promise.all([
        deliveryCities.getByParameters(),
        mealPreferences.getByParameters(),
        products.getByParameters({column: 'name', direction: 'asc', limit: 15}),
        weeklyMenusBasic.getByParameters({published: true}),
        travelMode.getByParameters({
          subscription_id: this.$route.params.id,
          active: 1,
        }),
        repeatOffenders.getByParameters({subscription_id: id}),
      ]);

      // extract the responses
      const [cityList, mealList, productList, weeklyMenuList, travelModes, repeatOffender] = response;
      this.deliveryCities = cityList.items;
      this.mealPreferences = mealList.items;
      this.products = productList.items;
      this.weeklyMenus = weeklyMenuList.items.map(week => Object.assign(week, {startDate: moment(week.startDate)}));
      this.travelModes = travelModes.items;
      this.repeatOffender = repeatOffender?.repeatOffender;
    },
    async fetchData(id) {
      await this.fetchAll(id);
      return Promise.all([subscriptions.getById(id), subscriptions.getAdminScheduleById(id), subscriptions.getDeliveryPreferences(id)]);
    },
    setRecipeQuantity(week, selected) {
      week.dirtySelections = true;
      const newSelectionIndex = week.selection.findIndex(select => select.recipe_id === selected.recipe_id);
      let result;
      if (newSelectionIndex > -1) {
        result = Object.assign(week.selection[newSelectionIndex], {quantity: selected.quantity});
      }
      else {
        result = week.selection.push(selected);
      }
      return result;
    },
    setMarketRecipeQuantity(week, selected) {
      week.dirtyMarketSelections = true;
      const newSelectionIndex = week.marketSelection.findIndex(select => select.recipe_id === selected.recipe_id);
      let result;
      if (newSelectionIndex > -1) {
        result = Object.assign(week.marketSelection[newSelectionIndex], {quantity: selected.quantity});
      }
      else {
        result = week.marketSelection.push(selected);
      }
      return result;
    },
    async skipUnskipWeek(week) {
      const resource = week.skipped ? 'skipById' : 'unskipById';
      try {
        this.loading = true;
        await subscriptions[resource](this.item.id, {
          startDates: [week.startDate.format('YYYY-MM-DD')],
        });
        this.resetDirty();
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.loading = false;
      }
    },
    async saveSubscription() {
      // check if form is valid first before the recipe changed check
      const isValid = await this.validateForm();
      if (!isValid) return;

      if (this.deliveryDirty) {
        const confirmation = confirm('You have unsaved delivery changes. They will get reset if you continue. Do you want to proceed?');
        if (!confirmation) {
          return;
        }
        this.deliveryDirty = false;
      }

      this.submit();
    },
    async handleRecipesChangedModal() {
      this.$refs.recipesChangedModal.toggle();

      return new Promise((resolve, reject) => {
        this.$refs.recipesChangedModal.$on('decline', () => {
          resolve(false);
        });

        this.$refs.recipesChangedModal.$on('confirm', () => {
          resolve(true);
        });
      });
    },
    async submitData(item) {
      const payload = Object.assign({}, item, {
        customer: item.customer.id,
        preferences: Object.assign({}, item.preferences, {
          deliveryDay: item.preferences.deliveryDay.id,
          deliveryTime: item.preferences.deliveryTime.id,
          mealPreference: item.preferences.mealPreference ? item.preferences.mealPreference.id : null,
          mealPlan: 4,
        }),
        product: item.product.id,
      });

      delete payload.preferences.firstDeliveryDate;

      if (this.isNew) {
        payload.preferences.firstDeliveryDate = item.preferences.firstDeliveryDate.format('YYYY-MM-DD');
      }

      delete payload.schedule;

      if (this.mainCancellationReasons.length > 0 || this.cancellationOther) {
        await subscriptions.submitExitSurveyById(payload.id, {
          mainReasons: this.mainCancellationReasons,
          otherReason: this.cancellationOther,
        });
      }

      const result = await subscriptions.saveOrUpdate(payload);

      this.subscriptionDirty = false;

      /**
       * All the changes made to the subscription by the admin
       * are saved in a single `Submit` action.
       *
       * We collect all these changes using `watch`, and throw them to
       * the trackers as a single object.
       *
       * It is left up to the tracker(s) to decide whether they want to
       * track these changes as individual events or as one.
       *
       * This page / component doesn't need to decide that for now.
       */
      this.$store.dispatch('events/track', {
        event: 'Admin Update Subscription',
        customer: this.item.customer,
        properties: {
          changes: this.changes,
          subscription: this.item,
        },
      });

      return Promise.all([result, subscriptions.getAdminScheduleById(result.item.id), subscriptions.getDeliveryPreferences(result.item.id)]);
    },
    async submitCustomWeek(week, index) {
      this.submitting = true;
      this.loading = true;
      week.success = false;
      week.errors = [];

      try {
        let updateSelection = false;
        let updateMarketSelection = false;
        if (week.productDirty) {
          await subscriptions.customizePlan(this.item.id, {
            product_id: week.product?.id,
            weekly_menu_start_date: moment(week.startDate).format('YYYY-MM-DD'),
          });

          // update week's selections
          if (week.selection.filter(item => item.quantity).length) {
            updateSelection = true;
          }

          // update week's market selections
          if (week.marketSelection.filter(item => item.quantity).length) {
            updateMarketSelection = true;
          }

          this.track({
            event: 'Admin changed box size',
            customer: this.item.customer,
            properties: {
              week: moment(week.startDate).format('YYYY-MM-DD'),
              number_of_people: week.product?.number_of_people,
              number_of_recipes: week.product?.number_of_recipes,
            },
          });
        }

        // check if update selection is required or any dirty selections
        if (updateSelection || week.dirtySelections) {
          await subscriptions.updateSelectionsById(this.item.id, {
            recipes: week.selection.filter(item => item.quantity),
            startDate: week.startDate.format('YYYY-MM-DD'),
          });
        }

        // check if update market selection is required or any dirty market selections
        if (updateMarketSelection || week.dirtyMarketSelections) {
          const body = {
            subscription_id: this.item.id,
            weekly_menu_id: week.weeklyMenuId,
            selections: week.marketSelection,
          };

          await market.updateSelections(body);
        }

        if (week.preferenceDirty) {
          const deliveryDay = this.deliveryDays?.find(day => day.name === week.deliveryDay);
          const deliveryTime = deliveryDay?.times?.find(time => time.name === week.deliveryTime);
          await subscriptions.customizeDelivery(this.item.id, {
            delivery_day_id: deliveryDay?.id,
            delivery_time_id: deliveryTime?.id,
            weekly_menu_start_date: moment(week.startDate).format('YYYY-MM-DD'),
          });

          this.track({
            event: 'Admin changed day/time',
            customer: this.item.customer,
            properties: {
              week: moment(week.startDate).format('YYYY-MM-DD'),
              delivery_day: deliveryDay?.name,
              delivery_time: deliveryTime?.name,
            },
          });
        }

        if (this.item.schedule[index]) {
          this.item.schedule[index].productDirty = this.item.schedule[index].preferenceDirty = false;
          this.item.schedule[index].success = true;
        }

        await this.reset();
      }
      catch (ex) {
        if (Object.keys(ex?.response?.data?.errors)?.length) {
          const errors = Object.values(ex?.response?.data?.errors);
          week.errors.push(errors);
        }
      }
      finally {
        this.submitting = false;
        this.loading = false;
      }
    },
    transformData([result, scheduleResult, deliveryPreferencesResult]) {
      // first remove the weeks which are past the cut off time
      // then grab all the recipes and format it
      scheduleResult.items = scheduleResult.items
        .filter(schedule => !pastCutOffTime(schedule.deliveryDate))
        .map(schedule => {
          const recipes = Recipes.transformToRecipeSwapFormat(schedule.recipes);

          // sort the recipes based on selections
          recipes.sort((a, b) => {
            const aSelection = schedule.selection.find(selection => selection.recipe_id === a.id);
            const bSelection = schedule.selection.find(selection => selection.recipe_id === b.id);

            // if no selections, check if the swaps have selections
            if (!aSelection && !bSelection) {
              if (a.swaps || b.swaps) {
                const aSwapSelection = a.swaps?.find(swap => schedule.selection.find(selection => selection.recipe_id === swap.id));
                const bSwapSelection = b.swaps?.find(swap => schedule.selection.find(selection => selection.recipe_id === swap.id));

                return this.sortFn(aSwapSelection, bSwapSelection);
              }
            }

            return this.sortFn(aSelection, bSelection);
          });

          // sort the market recipes based on market selections
          schedule.marketRecipes.sort((a, b) => {
            const aSelection = schedule.marketSelection.find(selection => selection.recipe_id === a.id);
            const bSelection = schedule.marketSelection.find(selection => selection.recipe_id === b.id);

            return this.sortFn(aSelection, bSelection);
          });

          return Object.assign(schedule, {recipes});
        });

      if (result.item.cancellationReasons) {
        if (result.item.cancellationReasons.mainReason) {
          this.cancellationReasons.filter(reason => result.item.cancellationReasons.mainReason.forEach(text => {
            if (reason.text === text) { reason.selected = true; }
          }));
        }
        this.cancellationOther = result.item.cancellationReasons.otherReason || '';
      }

      // reset the dirty flag
      this.subscriptionDirty = false;

      const hasTravelMode = week => {
        if (!this.travelModes.length) return false;

        const travelMode = this.travelModes.find(travel => {
          const startDate = moment(travel.start_date);
          const endDate = moment(travel.end_date);
          const deliveryDate = moment(week.deliveryDate);
          return deliveryDate.isBetween(startDate, endDate, null, '[]');
        });

        return !!travelMode;
      };

      const selectedCity = result.item.customer ? this.deliveryCities.find(x => x.name === result.item.customer.deliveryAddress.city) : null;
      const deliveryDays = selectedCity ? selectedCity.days : [];
      if (!deliveryPreferencesResult?.data?.length) {
        // try to fallback to the default delivery preferences
        if (result.item.preferences.deliveryDay && result.item.preferences.deliveryTime) {
          const day = deliveryDays.find(x => x.id === result.item.preferences.deliveryDay.id);
          const timesForDay = day.times;
          const time = timesForDay.find(x => x.id === result.item.preferences.deliveryTime.id);

          this.deliveryPreferences = [{
            id: null,
            day,
            time,
            timesForDay,
            splitNumber: 1,
          }];
        }
      }
      else {
        this.deliveryPreferences = this.transformDeliveryPreferences(deliveryPreferencesResult?.data, deliveryDays);
      }

      return Object.assign(result, {
        item: Object.assign(result.item, {
          cancellationDate: result.item.cancellationDate ? moment(result.item.cancellationDate) : null,
          creditCardNumber: (result.item.creditCard && result.item.creditCard.cardInfo) ? `${result.item.creditCard.cardInfo.card.first6}******${result.item.creditCard.cardInfo.card.last4}` : 'N/A',
          lastOrderDate: result.item.lastOrderDate ? moment(result.item.lastOrderDate) : null,
          nextDeliveryDate: result.item.nextDeliveryDate ? moment(result.item.nextDeliveryDate) : null,
          nextPaymentDate: result.item.nextPaymentDate ? moment(result.item.nextPaymentDate) : null,
          preferences: Object.assign(result.item.preferences, {
            firstDeliveryDate: result.item.preferences.firstDeliveryDate ? moment(result.item.preferences.firstDeliveryDate) : null,
          }),
          schedule: scheduleResult.items.map(week => Object.assign(week, {
            deliveryDate: week.deliveryDate ? moment(week.deliveryDate) : null,
            paymentDate: week.paymentDate ? moment(week.paymentDate) : null,
            startDate: moment(week.startDate),
            hasTravelMode: hasTravelMode(week) && week.skipped,
            errors: [],
            success: false,
          })),
        }),
      });
    },
    handleDeliveryDayChange(week, self = true) {
      if (self) {
        week.preferenceDirty = true;
      }
    },
    handleSubscriptionDeliveryDayChange(index) {
      this.deliveryDirty = true;

      if (!this.deliveryPreferences[index]) {
        return;
      }

      // for the changed index in delivery preference, calculate the timesForDay
      const day = this.deliveryDays.find(x => x.id === this.deliveryPreferences[index].day.id);
      this.deliveryPreferences[index].timesForDay = day?.times || [];

      const timesForDay = this.deliveryPreferences[index].timesForDay;
      const selectedTime = this.deliveryPreferences[index].time;

      if (selectedTime) {
        // check if selected time is available for the selected day, else set it to null
        if (!timesForDay.find(x => x.id === selectedTime.id)) {
          this.deliveryPreferences[index].time = null;
        }
      }
    },
    expandRow(week, index) {
      this.expandedRow = this.expandedRow === index ? -1 : index;
    },
    sortFn(a, b) {
      if (a && b) {
        return b.quantity - a.quantity;
      }
      if (a && !b) {
        return -1;
      }
      if (!a && b) {
        return 1;
      }
      return 0;
    },
    getGourmetPrice(recipe, product) {
      return recipe[`gourmetSurchargeFor${product.number_of_people}`];
    },
    getTransformedMarketRecipes(recipes) {
      const desserts = recipes.filter(recipe => recipe.type === 'dessert');
      const breakfasts = recipes.filter(recipe => recipe.type === 'breakfast');
      const sides = recipes.filter(recipe => recipe.type === 'side');

      return {
        'dessert': desserts,
        'breakfast': breakfasts,
        'side': sides,
      };
    },
    transformDeliveryPreferences(deliveryPreferencesResult, deliveryDays) {
      if (!deliveryPreferencesResult?.length) {
        return [];
      }

      return deliveryPreferencesResult.map(it => {
        const day = deliveryDays.find(x => x.id === it.delivery_day_id) ?? null;
        const timesForDay = day?.times || [];
        const time = timesForDay.find(x => x.id === it.delivery_time_id) ?? null;

        return {
          id: it.id,
          day,
          time,
          timesForDay,
          splitNumber: it.delivery_split_number,
        };
      }).sort((a, b) => a.splitNumber - b.splitNumber);
    },
    handleAddDelivery() {
      if (this.deliveryPreferences.length >= MAX_DELIVERIES) {
        this.$toasted.error(`Cannot add more than ${MAX_DELIVERIES} deliveries`);
        return;
      };

      // if first delivery is Wednesday, then can't add another delivery
      if (this.deliveryPreferences.some(it => it.day?.name === 'Wednesday')) {
        this.$toasted.error('Cannot add multiple delivery when Wednesday is chosen as first delivery');
        return;
      }

      this.deliveryPreferences.push({
        id: null,
        day: null,
        time: null,
        timesForDay: [],
        splitNumber: this.deliveryPreferences.length + 1,
      });
    },
    handleRemoveDelivery(index) {
      this.deliveryPreferences.splice(index, 1);
      this.deliveryDirty = true;
    },
    async validateDeliveryForm() {
      const form = this.$refs.deliveryForm;
      const valid = (await this.validate()) && form.checkValidity();
      if (valid) return true;
      this.invalid = true;
      form.classList.toggle('was-validated', this.invalid);
      if (!this.customScrollToError) {
        window.scrollTo(0, 0);
      }
      return false;
    },
    async handleSaveDelivery() {
      const isValid = await this.validateDeliveryForm();
      if (!isValid) return;

      this.submitting = true;
      try {
        const payload = {
          delivery_preferences: this.deliveryPreferences.map(it => ({
            id: it.id,
            delivery_day_id: it.day?.id ?? null,
            delivery_time_id: it.time?.id ?? null,
            delivery_split_number: it.splitNumber,
          })),
        };

        const {data} = await subscriptions.updateDeliveryPreferences(this.item.id, payload);
        this.deliveryPreferences = this.transformDeliveryPreferences(data, this.deliveryDays);
        this.deliveryDirty = false;
        this.$toasted.success('Delivery preferences saved successfully');
      }
      catch (error) {
        console.error(error);
        if (error.response?.status === 422 && error.response?.data.message) {
          this.$toasted.error(error.response.data.message);
          return;
        }

        captureException(error);
        this.$toasted.error('Something went wrong. Couldnt save your changes');
      }
      finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped>
.recipe-columns {
  display: flex;
  flex-direction: column;
  max-height: 480px;
  overflow: auto;
}

.disable-row {
  opacity: 0.5;
  pointer-events: none;
}

.bg-customized {
  background-color: #FDF4EC;
}

.travel-icon {
  position: absolute;
  left: 9px;
  width: 16px;
  height: 16px;
}

.recipe-category-container {
  display: flex;

  .category-separator {
    width: 1px;
    background-color: #E0E5EC;
  }
}

.dinner-category-container, .market-category-container {
  flex: 1;

  h5 {
    padding: 16px;
    padding-left: 24px;
    margin-bottom: 0;
    border-bottom: 1px solid #E0E5EC;
  }

  h6 {
    padding: 16px;
    padding-left: 24px;
    margin-bottom: 0;
    border-top: 1px solid #E0E5EC;
    border-bottom: 1px solid #E0E5EC;
    text-transform: capitalize;
  }
}

.header-row {
  &:hover {
    background-color: #E0E5EC;
  }
}

.highlighted-row {
  background-color: #e5edf8;
  outline: 1px solid #388bff;
  border-right: 1px solid #388bff;
  border-left: 1px solid #388bff;

  &:hover {
    background-color: #e5edf8;
  }
}

.save-btn-container {
  vertical-align: bottom;

  button {
    width: 100%;
  }
}

.row-default {
  &:hover {
    background-color: #E0E5EC;
  }
}

.row-selected {
  background-color: #d2ecb8;

  &:hover {
    background-color: #d2ecb8;
  }
}

.customize-row {
  border-bottom: 20px solid #f5f7fb;

  hr {
    background-color: #E0E5EC;
    width: 100%;
    height: 1px;

    margin: 0;
    padding: 0;
  }
}

.market-type-section {
  margin: 8px 0;
}

.delivery-input {
  padding: 0;
  flex: 1;
  max-width: 144px;

  &:first-of-type {
    padding-right: 4px;
  }
}

.reduced-card-space {
  padding: 12px;

  &:has(+ .reduced-card-space) {
    padding-bottom: 0;
  }

  & + .reduced-card-space {
    border: none;
    padding-top: 0;
  }

  > :last-child {
    margin-bottom: 1rem;
  }
}

.delivery-row {
  display: flex;
  gap: 8px;
  max-height: fit-content;

  > div {
    height: fit-content;
  }
}

.delete-icon {
  cursor: pointer;
  font-size: 20px;
  align-self: center;
  margin-top: 8px;
  margin-bottom: 0 !important;

  &:hover {
    color: #a11918 !important;
  }
}
</style>
