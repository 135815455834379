<template>
  <b-modal
    v-model="show"
    size="md"
    title="Recipe selection confirmation warning"
    hide-footer>
    <template slot="modal-header-close">
      <wbr/>
    </template>
    <p>Are you sure you want to continue?</p>
    <p>This will remove all the selected recipes for the weeks where off peak recipes are selected!</p>
    <div class="d-flex justify-content-end pt-6">
      <button class="btn btn-danger mr-2" @click.prevent="emit('confirm')">Confirm</button>
      <button class="btn btn-primary" @click.prevent="emit('decline')">Cancel</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'RecipesChangedModal',
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    emit(type) {
      this.$emit(type);
      this.show = false;
    },
  },
};
</script>
