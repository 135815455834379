import moment from 'moment-timezone';

export const pastCutOffTime = date => {
  const dubaiHour = new Date().getUTCHours() + 4;

  const deliveryDate = moment(date);
  const diffInDays = deliveryDate.diff(moment(), 'days');

  // within 2 days of the delivery date
  if (diffInDays < 1) {
    return true;
  }

  // delivery date is 2 days later
  if (diffInDays === 1) {
    // past 1PM
    return dubaiHour >= 13;
  }

  return false;
};
