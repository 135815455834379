<template>
  <dimmer :active="loading">
    <table class="table text-nowrap">
      <thead>
        <tr>
          <th>Order</th>
          <th>Status</th>
          <th>Created at</th>
          <th>Delivery</th>
          <th>Box</th>
          <th>Meal plan</th>
          <th class="text-center">Box price</th>
          <th class="text-center">Recipe surcharge</th>
          <th class="text-center">Delivery fee</th>
          <th class="text-center">Discount</th>
          <th class="text-center">Store credit used</th>
          <th class="text-center">VAT</th>
          <th class="text-center">Grand total</th>
          <th>Recipe selection</th>
          <th class="text-center">Allocated</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="item.id"
          :class="{'outline outline-danger': item.complaints.length}">
          <td>
            <router-link :to="`/orders/${item.id}`">#{{item.id}}</router-link>
          </td>
          <td>
            {{status(item)}}
          </td>
          <td>
            {{moment.utc(item.createdAt).local().format('D MMM YYYY')}}
          </td>
          <td>
            {{moment(item.deliveryDate).format('dddd, D MMM YYYY')}}
          </td>
          <td>
            {{item.productFullName}}
          </td>
          <td>
            {{item.mealPlanName}}
          </td>
          <td class="text-center">
            AED {{numeral(item.receipt.boxPrice).format('0,0[.]00')}}
          </td>
          <td class="text-center">
            AED {{numeral(item.receipt.gourmetSurcharge).format('0,0[.]00')}}
          </td>
          <td class="text-center">
            AED {{numeral(item.receipt.deliveryFee).format('0,0[.]00')}}
          </td>
          <td class="text-center">
            AED {{numeral(item.receipt.discount).format('0,0[.]00')}}
          </td>
          <td class="text-center">
            AED {{numeral(item.receipt.storeCredit).format('0,0[.]00')}}
          </td>
          <td class="text-center">
            AED {{numeral(item.receipt.vat).format('0,0[.]00')}}
          </td>
          <td class="text-center">
            AED {{numeral(item.receipt.grandTotal).format('0,0[.]00')}}
          </td>
          <td>
            {{item.recipes.map(recipe => recipe.id).join(', ')}}
          </td>
          <td class="text-center">
            <i v-if="item.allocated" class="fe fe-check"></i>
          </td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="14">The subscription does not have any orders.</td>
        </tr>
      </tbody>
    </table>
  </dimmer>
</template>

<script>

import {orders} from '@/services';
import OrderStatuses from '@hellochef/shared-js/enums/OrderStatuses';

export default {
  props: [
    'value',
  ],
  data() {
    return {
      items: [],
    };
  },

  created() {
    this.loading = true;

    orders.getByParameters({
      column: 'created_at',
      direction: 'desc',
      limit: 10,
      subscription: this.value.id,
      with: 'complaints.complaint.category',
    })
      .then(result => {
        this.items = result.items;
        this.$emit('orders', this.items);
        this.loading = false;
      });
  },
  methods: {

    status(order) {
      return Object.keys(OrderStatuses).find(key => OrderStatuses[key] === order.status);
    },
  },
};

</script>
